import React from 'react';
import CareerPosition from '../component/Career/careerPositions';
import FooterComponet from '../component/footerComponent';
import { graphql } from 'gatsby';
import BlogHomeComponent from '../component/Blog/blogHomeComponent';
import CareerImage from '../images/career_banner.png';
import Layout from '../component/layout';
const Career = props => {
  const {
    data: {
      wpPage: {
        seo,
        careerPositionSection: {
          careerPositionBackend,
          careerPositionContentManager,
          careerPositionDescription,
          careerPositionDesigner,
          careerPositionDevops,
          careerPositionFornend,
          careerPositionLocation,
          careerPositionMarketingManager,
          careerPositionProjectManagement,
          careerPositionProjectManager,
          careerPositionQa,
          careerPositionSocialMediaMarketing,
          careerPositionSupport,
          careerPositionTechnicalWriter,
          careerPositionText,
          careerPositionTitle,
        },
        homePageHeaderSection: { homeHeaderTitle, homeHeaderDescription },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <BlogHomeComponent
        blogTitle={homeHeaderTitle}
        blogDescription={homeHeaderDescription}
        ourWorkImage={CareerImage}
      />
      <CareerPosition
        careerPositionTitle={careerPositionTitle}
        careerPositionSubtitle={careerPositionDescription}
        careerPositionFrontEnd={careerPositionFornend}
        careerPositionText={careerPositionText}
        careerPositionLocation={careerPositionLocation}
        careerPositionBackEnd={careerPositionBackend}
        careerPositionDevOps={careerPositionDevops}
        careerPositionUXDesigner={careerPositionDesigner}
        careerPositionQA={careerPositionQa}
        careerPositionProjectManagement={careerPositionProjectManagement}
        careerPositionProjectManager={careerPositionProjectManager}
        careerPositionProjectSeniorCostumerSupport={careerPositionSupport}
        careerPositionSeniorContentManager={careerPositionMarketingManager}
        careerPositionProjectSocialMediaMarketingSpecialist={
          careerPositionSocialMediaMarketing
        }
        careerPositionMarketingManager={careerPositionContentManager}
        careerPositionProjectSeniorTechnicalWriter={
          careerPositionTechnicalWriter
        }
      />
      <FooterComponet />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "career-page" }) {
      seo {
        metaDesc
        title
      }
      careerPositionSection {
        careerPositionBackend
        careerPositionContentManager
        careerPositionCostumerSupport
        careerPositionDescription
        careerPositionDesigner
        careerPositionDevops
        careerPositionFornend
        careerPositionLocation
        careerPositionMarketingManager
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionQa
        careerPositionSocialMediaMarketing
        careerPositionSupport
        careerPositionTechnicalWriter
        careerPositionText
        careerPositionTitle
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default Career;
