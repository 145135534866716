import React, { useState } from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'
import {  Pagination,Navigation } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from "gatsby";
SwiperCore.use([Autoplay]);
const CareerPosition = (props) =>{
    const {
        careerPositionSubtitle, 
        careerPositionTitle,
        careerPositionFrontEnd,
        careerPositionText,
        careerPositionLocation,
        careerPositionBackEnd,
        careerPositionDevOps,
        careerPositionUXDesigner,
        careerPositionQA,
        careerPositionProjectManagement,
        careerPositionProjectManager,
        careerPositionProjectSeniorCostumerSupport,
        careerPositionSeniorContentManager,
        careerPositionProjectSocialMediaMarketingSpecialist,
        careerPositionMarketingManager,
        careerPositionProjectSeniorTechnicalWriter
    } = props;
    const [isActive, setIsActive] = useState(0);
    return (
      <div className="career-position-section">
        <div className="full-container">
          <div className="home-section-textIcone">
            <TitleIcone className="home-section-titleIcone" />
            <h2 className="ourWork-project-section-text-title">
              {careerPositionTitle}
            </h2>
          </div>
          <p className="career-position-section-subtitle">
            {careerPositionSubtitle}
          </p>
          <div className="career-position-section-nav">
            <Swiper
              navigation={false}
              slidesPerView={2.5}
              pagination={false}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                768: {
                  slidesPerView: 5,
                },
              }}
            >
              <ul className="career-position-section-nav-list">
                <SwiperSlide>
                  <li className="career-position-section-nav-list-item">
                    <button
                      className={`${
                        0 === isActive
                          ? "career-position-section-button-active"
                          : "career-position-section-button"
                      }`}
                      onClick={() => {
                        setIsActive(0);
                      }}
                    >
                      ALL CATEGORIES
                    </button>
                  </li>
                </SwiperSlide>
                <SwiperSlide>
                  <li className="career-position-section-nav-list-item">
                    <button
                      className={`${
                        1 === isActive
                          ? "career-position-section-button-active"
                          : "career-position-section-button"
                      }`}
                      onClick={() => {
                        setIsActive(1);
                      }}
                    >
                      DESIGN
                    </button>
                  </li>
                </SwiperSlide>
                <SwiperSlide>
                  <li className="career-position-section-nav-list-item">
                    <button
                      className={`${
                        2 === isActive
                          ? "career-position-section-button-active"
                          : "career-position-section-button"
                      }`}
                      onClick={() => {
                        setIsActive(2);
                      }}
                    >
                      MARKETING
                    </button>
                  </li>
                </SwiperSlide>
                <SwiperSlide>
                  <li className="career-position-section-nav-list-item">
                    <button
                      className={`${
                        3 === isActive
                          ? "career-position-section-button-active"
                          : "career-position-section-button"
                      }`}
                      onClick={() => {
                        setIsActive(3);
                      }}
                    >
                      FRONT-END
                    </button>
                  </li>
                </SwiperSlide>
                <SwiperSlide>
                  <li className="career-position-section-nav-list-item">
                    <button
                      className={`${
                        4 === isActive
                          ? "career-position-section-button-active"
                          : "career-position-section-button"
                      }`}
                      onClick={() => {
                        setIsActive(4);
                      }}
                    >
                      BACK-END
                    </button>
                  </li>
                </SwiperSlide>
              </ul>
            </Swiper>
          </div>
          {isActive === 0 && (
            <div className="row">
              <div className="col-lg-6">
                <div className="career-position-section-wrapper">
                  <Link
                    className="career-position-section-text"
                    to="/senior-front-end"
                  >
                    <p className="career-position-section-text">
                      {careerPositionFrontEnd}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link className="career-position-section-text" to="/devops">
                    <p className="career-position-section-text">
                      {careerPositionDevOps}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/quality-assurance"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionQA}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/project-manager"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionProjectManager}
                    </p>

                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/marketing-manager"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionSeniorContentManager}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/senior-content-manager"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionMarketingManager}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="career-position-section-wrapper">
                  <Link
                    to="/senior-back-end"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionBackEnd}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/senior-ux-designer"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionUXDesigner}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/project-management"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionProjectManagement}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/senior-customer-support"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionProjectSeniorCostumerSupport}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/social-media-marketing-specialist"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionProjectSocialMediaMarketingSpecialist}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="career-position-section-wrapper">
                  <Link
                    to="/senior-technical-write"
                    className="career-position-section-text"
                  >
                    <p className="career-position-section-text">
                      {careerPositionProjectSeniorTechnicalWriter}
                    </p>
                    <div className="career-position-section-location">
                      <p className="career-position-section-location-text">
                        {careerPositionText}
                      </p>
                      <p className="career-position-section-location-text">
                        {careerPositionLocation}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {isActive === 1 && (
            <div className="career-position-section-wrapper">
              <Link
                to="/senior-ux-designer"
                className="career-position-section-text"
              >
                <p className="career-position-section-text">
                  {careerPositionUXDesigner}
                </p>
                <div className="career-position-section-location">
                  <p className="career-position-section-location-text">
                    {careerPositionText}
                  </p>
                  <p className="career-position-section-location-text">
                    {careerPositionLocation}
                  </p>
                </div>
              </Link>
            </div>
          )}
          {isActive === 2 && (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="career-position-section-wrapper">
                    <Link
                      to="/marketing-manager"
                      className="career-position-section-text"
                    >
                      <p className="career-position-section-text">
                        {careerPositionSeniorContentManager}
                      </p>
                      <div className="career-position-section-location">
                        <p className="career-position-section-location-text">
                          {careerPositionText}
                        </p>
                        <p className="career-position-section-location-text">
                          {careerPositionLocation}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="career-position-section-wrapper">
                    <Link
                      to="/senior-content-manager"
                      className="career-position-section-text"
                    >
                      <p className="career-position-section-text">
                        {careerPositionMarketingManager}
                      </p>
                      <div className="career-position-section-location">
                        <p className="career-position-section-location-text">
                          {careerPositionText}
                        </p>
                        <p className="career-position-section-location-text">
                          {careerPositionLocation}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="career-position-section-wrapper">
                    <Link
                      to="/social-media-marketing-specialist"
                      className="career-position-section-text"
                    >
                      <p className="career-position-section-text">
                        {careerPositionProjectSocialMediaMarketingSpecialist}
                      </p>
                      <div className="career-position-section-location">
                        <p className="career-position-section-location-text">
                          {careerPositionText}
                        </p>
                        <p className="career-position-section-location-text">
                          {careerPositionLocation}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isActive === 3 && (
            <div className="career-position-section-wrapper">
              <Link
                className="career-position-section-text"
                to="/senior-front-end"
              >
                <p className="career-position-section-text">
                  {careerPositionFrontEnd}
                </p>
                <div className="career-position-section-location">
                  <p className="career-position-section-location-text">
                    {careerPositionText}
                  </p>
                  <p className="career-position-section-location-text">
                    {careerPositionLocation}
                  </p>
                </div>
              </Link>
            </div>
          )}
          {isActive === 4 && (
            <div className="career-position-section-wrapper">
              <Link
                to="/senior-back-end"
                className="career-position-section-text"
              >
                <p className="career-position-section-text">
                  {careerPositionBackEnd}
                </p>
                <div className="career-position-section-location">
                  <p className="career-position-section-location-text">
                    {careerPositionText}
                  </p>
                  <p className="career-position-section-location-text">
                    {careerPositionLocation}
                  </p>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
}
export default CareerPosition